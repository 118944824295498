import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import React from 'react';
import './index.css';
import { hideClaimDialog } from '../../actions/myAccount';
import variables from '../../utils/variables';
import { Button } from '@mui/material';
import successIcon from '../../assets/claim/success.svg';
import errorIcon from '../../assets/claim/error.svg';
import { MARKETPLACE_URL } from '../../config';

const ClaimDialog = (props) => {
    const handleClose = () => {
        props.handleClose();
    };

    const handleViewNFT = () => {
        window.open(MARKETPLACE_URL + '/c/' + props.denomID + '/' + props.nftID);
    };

    return (
        <Dialog
            aria-describedby="account-connect-dialog-description"
            aria-labelledby="account-connect-dialog-title"
            className="claim_dialog"
            open={props.open}
            onClose={handleClose}>
            <div className="claim_dialog_section">
                <div className={props.success ? 'section success' : 'section fail'}>
                    {props.success
                        ? <h2>{variables[props.lang]['successfully_claimed']}</h2>
                        : <h2>{variables[props.lang]['claiming_unsuccessful']}</h2>}
                    {props.success
                        ? <img alt="success" src={successIcon}/>
                        : <img alt="failed" src={errorIcon}/>}
                    <div className="actions">
                        {props.success
                            ? <Button
                                className="primary_button"
                                onClick={handleViewNFT}>
                                {variables[props.lang].view}
                            </Button>
                            : <Button
                                className="primary_button"
                                onClick={handleClose}>
                                {variables[props.lang].okay}
                            </Button>}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

ClaimDialog.propTypes = {
    denomID: PropTypes.string.isRequired,
    fail: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    nftID: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.myAccount.claimDialog.open,
        success: state.myAccount.claimDialog.success,
        fail: state.myAccount.claimDialog.fail,
        nftID: state.myAccount.claimDialog.nftID,
        denomID: state.myAccount.claimDialog.denomID,
        lang: state.language,
    };
};

const actionToProps = {
    handleClose: hideClaimDialog,
};

export default connect(stateToProps, actionToProps)(ClaimDialog);
