import {
    ASSET_WATCH_URL_FETCH_ERROR,
    ASSET_WATCH_URL_FETCH_IN_PROGRESS,
    ASSET_WATCH_URL_FETCH_SUCCESS,
    CLAIM_DIALOG_HIDE,
    CLAIM_DIALOG_SHOW,
    CLAIM_STATUS_SET,
    CLEAR_USER_NFT_S_SET,
    ELIGIBLE_CHECK_ERROR,
    ELIGIBLE_CHECK_IN_PROGRESS,
    ELIGIBLE_CHECK_SUCCESS,
    FETCH_USER_NFT_S_ERROR,
    FETCH_USER_NFT_S_IN_PROGRESS,
    FETCH_USER_NFT_S_SUCCESS,
    IV_PROJECTS_LIST_FETCH_ERROR,
    IV_PROJECTS_LIST_FETCH_IN_PROGRESS,
    IV_PROJECTS_LIST_FETCH_SUCCESS,
    MINT_NFT_ERROR,
    MINT_NFT_IN_PROGRESS,
    MINT_NFT_SUCCESS,
    MINT_QUEUE_FETCH_ERROR,
    MINT_QUEUE_FETCH_IN_PROGRESS,
    MINT_QUEUE_FETCH_SUCCESS,
    MINT_REQUEST_FETCH_ERROR,
    MINT_REQUEST_FETCH_IN_PROGRESS,
    MINT_REQUEST_FETCH_SUCCESS,
    MY_ACCOUNT_TAB_SET,
    NFT_CLAIM_STATUS_CHECK_ERROR,
    NFT_CLAIM_STATUS_CHECK_IN_PROGRESS,
    NFT_CLAIM_STATUS_CHECK_SUCCESS,
    NFT_FETCH_ERROR,
    NFT_FETCH_IN_PROGRESS,
    NFT_FETCH_SUCCESS,
    PROJECTS_LIST_FETCH_ERROR,
    PROJECTS_LIST_FETCH_IN_PROGRESS,
    PROJECTS_LIST_FETCH_SUCCESS,
    USER_NAME_VALUE_SET,
} from '../constants/myAccount';
import {
    getWatchVideoURL,
    urlCheckNFTClaim,
    urlEligibleCheck, urlFetchIVProjectsList,
    urlFetchMintQueue,
    urlFetchMintRequest,
    urlFetchProjectsList,
    urlMintNFT,
    urlMyNFTs,
    urlNFT,
} from '../constants/url';
import Axios from 'axios';

export const setClearUserNFTs = () => {
    return {
        type: CLEAR_USER_NFT_S_SET,
    };
};

export const setMyAccountTab = (value) => {
    return {
        type: MY_ACCOUNT_TAB_SET,
        value,
    };
};

export const showClaimDialog = (value, projectID) => {
    return {
        type: CLAIM_DIALOG_SHOW,
        value,
        projectID,
    };
};

export const hideClaimDialog = () => {
    return {
        type: CLAIM_DIALOG_HIDE,
    };
};

export const setUserName = (value) => {
    return {
        type: USER_NAME_VALUE_SET,
        value,
    };
};

const fetchUserNFTsSuccess = (result, denom, skip, limit, count) => {
    return {
        type: FETCH_USER_NFT_S_SUCCESS,
        result,
        denom,
        skip,
        limit,
        count,
    };
};

const fetchUserNFTsError = (message) => {
    return {
        type: FETCH_USER_NFT_S_ERROR,
        message,
    };
};

const fetchUserNFTsInProgress = () => {
    return {
        type: FETCH_USER_NFT_S_IN_PROGRESS,
    };
};

export const fetchUserNFTs = (address, denom, skip, limit, cb) => (dispatch) => {
    dispatch(fetchUserNFTsInProgress());

    const url = urlMyNFTs(address, denom, skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchUserNFTsSuccess(res.data && res.data.result.list,
                denom, skip, limit, res.data && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result.list, res.data && res.data.result.count);
            }
        }
    }).catch((error) => {
        dispatch(fetchUserNFTsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
        if (cb) {
            cb(null);
        }
    });
};

const fetchWatchURLInProgress = () => {
    return {
        type: ASSET_WATCH_URL_FETCH_IN_PROGRESS,
    };
};

const fetchWatchURLSuccess = (value) => {
    return {
        type: ASSET_WATCH_URL_FETCH_SUCCESS,
        value,
    };
};

const fetchWatchURLError = (message) => {
    return {
        type: ASSET_WATCH_URL_FETCH_ERROR,
        message,
    };
};

export const fetchWatchURL = (Id, deviceId, cb) => (dispatch) => {
    dispatch(fetchWatchURLInProgress());
    const url = getWatchVideoURL(Id, deviceId);

    Axios.get(url, {
        headers: {
            Accept: 'application/json',
        },
    }).then((res) => {
        dispatch(fetchWatchURLSuccess(res.data && res.data.result && res.data.result.assetUrl));
        cb(null, res.data && res.data.result && res.data.result.assetUrl);
    }).catch((error) => {
        dispatch(fetchWatchURLError(error.response ? error.response.data.message : error.message));
        cb(error);
    });
};

const fetchProjectsListInProgress = () => {
    return {
        type: PROJECTS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchProjectsListSuccess = (value) => {
    return {
        type: PROJECTS_LIST_FETCH_SUCCESS,
        value,
        variant: 'success',
    };
};

const fetchProjectsListError = (message) => {
    return {
        type: PROJECTS_LIST_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchProjectsList = (address, cb) => (dispatch) => {
    dispatch(fetchProjectsListInProgress());

    const URL = urlFetchProjectsList(address);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            const list = res.data && res.data.result && res.data.result.list && res.data.result.list.length && res.data.result.list;
            dispatch(fetchProjectsListSuccess(list, res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(list);
            }
        })
        .catch((error) => {
            dispatch(fetchProjectsListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchIVProjectsListInProgress = () => {
    return {
        type: IV_PROJECTS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchIVProjectsListSuccess = (value) => {
    return {
        type: IV_PROJECTS_LIST_FETCH_SUCCESS,
        value,
        variant: 'success',
    };
};

const fetchIVProjectsListError = (message) => {
    return {
        type: IV_PROJECTS_LIST_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchIVProjectsList = (address, interactiveVideoID, cb) => (dispatch) => {
    dispatch(fetchIVProjectsListInProgress());

    const URL = urlFetchIVProjectsList(address, interactiveVideoID);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            const list = res.data && res.data.result && res.data.result.list && res.data.result.list.length && res.data.result.list;
            dispatch(fetchIVProjectsListSuccess(list, res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(list);
            }
        })
        .catch((error) => {
            dispatch(fetchIVProjectsListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const mintNFTInProgress = () => {
    return {
        type: MINT_NFT_IN_PROGRESS,
    };
};

const mintNFTSuccess = (value) => {
    return {
        type: MINT_NFT_SUCCESS,
        value,
    };
};

const mintNFTError = (message) => {
    return {
        type: MINT_NFT_ERROR,
        message,
        variant: 'error',
    };
};

export const mintNFT = (projectID, data, cb) => (dispatch) => {
    dispatch(mintNFTInProgress());

    const URL = urlMintNFT(projectID);
    Axios.post(URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(mintNFTSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(mintNFTError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchMintRequestInProgress = () => {
    return {
        type: MINT_REQUEST_FETCH_IN_PROGRESS,
    };
};

const fetchMintRequestSuccess = (value, projectID) => {
    return {
        type: MINT_REQUEST_FETCH_SUCCESS,
        value,
        projectID,
    };
};

const fetchMintRequestError = (message) => {
    return {
        type: MINT_REQUEST_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchMintRequest = (address, projectID, cb) => (dispatch) => {
    dispatch(fetchMintRequestInProgress());

    const URL = urlFetchMintRequest(address, projectID);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchMintRequestSuccess(res.data && res.data.result && res.data.result.list, projectID));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list);
            }
        })
        .catch((error) => {
            dispatch(fetchMintRequestError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchMintQueueInProgress = () => {
    return {
        type: MINT_QUEUE_FETCH_IN_PROGRESS,
    };
};

const fetchMintQueueSuccess = (value, projectID) => {
    return {
        type: MINT_QUEUE_FETCH_SUCCESS,
        value,
        projectID,
    };
};

const fetchMintQueueError = (message) => {
    return {
        type: MINT_QUEUE_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchMintQueue = (address, projectID, cb) => (dispatch) => {
    dispatch(fetchMintQueueInProgress());

    const URL = urlFetchMintQueue(address, projectID);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchMintQueueSuccess(res.data && res.data.result, projectID));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchMintQueueError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchNFTInProgress = () => {
    return {
        type: NFT_FETCH_IN_PROGRESS,
    };
};

const fetchNFTSuccess = (value, nftID) => {
    return {
        type: NFT_FETCH_SUCCESS,
        value,
        nftID,
    };
};

const fetchNFTError = (message) => {
    return {
        type: NFT_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchNFT = (nftID) => (dispatch) => {
    dispatch(fetchNFTInProgress());

    const URL = urlNFT(nftID);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchNFTSuccess(res.data && res.data.result, nftID));
        })
        .catch((error) => {
            dispatch(fetchNFTError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const eligibleCheckInProgress = () => {
    return {
        type: ELIGIBLE_CHECK_IN_PROGRESS,
    };
};

const eligibleCheckSuccess = (value, interactiveVideoId) => {
    return {
        type: ELIGIBLE_CHECK_SUCCESS,
        value,
        interactiveVideoId,
    };
};

const eligibleCheckError = (message) => {
    return {
        type: ELIGIBLE_CHECK_ERROR,
        message,
        variant: 'error',
    };
};

export const eligibleCheck = (interactiveVideoId, participantId) => (dispatch) => {
    dispatch(eligibleCheckInProgress());

    const URL = urlEligibleCheck(interactiveVideoId, participantId);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(eligibleCheckSuccess(res.data && res.data.result, interactiveVideoId));
        })
        .catch((error) => {
            dispatch(eligibleCheckError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const checkNFTClaimStatusInProgress = () => {
    return {
        type: NFT_CLAIM_STATUS_CHECK_IN_PROGRESS,
    };
};

export const checkNFTClaimStatusSuccess = (value, denom) => {
    return {
        type: NFT_CLAIM_STATUS_CHECK_SUCCESS,
        value,
        denom,
    };
};

const checkNFTClaimStatusError = (message) => {
    return {
        type: NFT_CLAIM_STATUS_CHECK_ERROR,
        message,
    };
};

export const checkNFTClaimStatus = (denom, address) => (dispatch) => {
    dispatch(checkNFTClaimStatusInProgress());

    const url = urlCheckNFTClaim(denom, address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        dispatch(checkNFTClaimStatusSuccess(res.data && res.data.result && res.data.result.list, denom));
    }).catch((error) => {
        dispatch(checkNFTClaimStatusError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

export const setClaimStatus = (success, fail, nftID, denomID) => {
    return {
        type: CLAIM_STATUS_SET,
        success,
        fail,
        nftID,
        denomID,
    };
};
